import React, { useEffect, useState } from 'react';
import { fetchItems, ItemActions } from '../../store/reducers/items';
import ItemCard from '../../components/common/ItemCard';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import icons from '../../constants/icons';
import FormField from '../../components/common/FormField';
import endpoints from '../../constants/endpoints';
import apiClient from '../../interceptor';
import useSnackbar from '../../hooks/useSnackbar';
import { useAuth } from '../../contexts/AuthProvider';
import { PageLoading } from '../../components/common/PageLoading';
import { ImageContext } from '../../contexts/ImageContext';
import { ImageBox } from '../../components/items/ImageBox';
import ShowEmpty from '../../components/items/EmptyItems';
import { useNavigate } from 'react-router-dom';

const itemCreateApi = endpoints.items.create;

const AdminProducts = () => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.items);
    const [newForm, setNewForm] = useState(false);
    const {user, loading: authorizing} = useAuth();
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(fetchItems()); // Fix: Call the action creator
    }, [dispatch]);

    useEffect(() => {
        if(!authorizing && !user) {
            navigate('/collections')
        }
    }, [authorizing, user])

    if(authorizing) return <PageLoading />
    

    return (
        user.role === 'regular' ? navigate('/collections') : <section className="container">
            <Heading setNewForm={setNewForm} />

            {
                <>
                    {data?.length ? <RenderItems items={data} /> : <ShowEmpty />}
        
                    <NewItem
                        show={newForm}
                        close={() => setNewForm(false)}
                    />
                </>
            }
        </section>
    );
};

const Heading = ({ setNewForm }) => {
    return (
        <div className='centerRY justify-between gap-5 font-[Montserrat-B] md:py-5'>
            <h1 className="text-center text-2xl md:text-4xl">Admin : <span className="text-yellow">Products</span></h1>
            <button
                className="px-[12px] py-[8px] rounded bg-yellow text-black centerRXY gap-2"
                onClick={() => setNewForm(true)}
            >
                <span>Create</span>
                <Icon icon={icons.plus} />
            </button>
        </div>
    );
};

const RenderItems = ({ items }) => {
    return (
        <div className="grid grid-cols-2 gap-2 md:grid-cols-3 xl:gap-5">
            {items.map((each, index) => (
                <ItemCard item={each} key={index} />
            ))}
        </div>
    );
};

const NewItem = ({ show, close }) => {
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch()

    const [images, setImages] = useState([
        { image: "", file: undefined },
        { image: "", file: undefined },
        { image: "", file: undefined },
        { image: "", file: undefined }
    ]);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        title: '',
        description: '',
        price: 17.5,
        sizes: ''
    });

    // Convert bytes to KB or MB
    const formatSize = (bytes) => {
        if (bytes < 1024) return `${bytes} bytes`;
        else if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
        else return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
    };

    // Calculate total file size
    const totalFileSize = images.reduce((total, img) => total + (img.file?.size ?? 0), 0);

    const handleChange = ({ name, value }) => {
        setForm({ ...form, [name]: value });
    };

    const removeImage = (index) => {
        setImages(currState => {
            const newState = [...currState];
            newState[index] = { image: "", file: undefined };
            return newState;
        });
    }

    const setBase64Image = (base64, file, index) => {
        setImages(currState => {
            const newState = [...currState];
            newState[index] = { image: base64, file };
            return newState;
        });
    };

    const updateFileSizes = (file, index) => {
        setImages(currState => {
            const newState = [...currState];
            newState[index].file = file;
            return newState;
        });
    };

    const validateForm = () => {
        const { title, price, sizes } = form;
    
        if (!(title.trim() && price && sizes.trim())) {
            showSnackbar('Please fill in all required fields.', { variant: 'error' });
            return false;
        }
    
        const sizeArray = sizes.split(',')
                               .filter(each => each.trim() !== "")
                               .map(each => each.trim());
    
        if (sizeArray.length < 1) {
            showSnackbar('Shoe sizes are required in this format 43,44,45', { variant: 'error' });
            return false;  
        }
    
        if (images.every(image => image.image === "")) {
            showSnackbar('Please upload at least one image.', { variant: 'error' });
            return false;
        }

        // Check total file size
        const totalFileSize = images.reduce((total, img) => total + (img.file?.size || 0), 0);
        if (totalFileSize > 2 * 1024 * 1024) {
            showSnackbar('Total file size exceeds 2MB limit.', { variant: 'error' });
            return false;
        }
    
        return true;
    };
    
    const handleSubmit = async () => {
        if (!validateForm()) return;
    
        setLoading(true);
    
        const sizeArray = form.sizes
            .split(',')
            .filter(each => each.trim() !== "")
            .map(each => each.trim());
    
        const fd = new FormData();
        fd.append('title', form.title);
        fd.append('description', form.description);
        fd.append('price', form.price);
        fd.append('sizes', JSON.stringify(sizeArray));
    
        images.forEach((img, index) => {
            if (img.file) {
                fd.append('files', img.file); // Appending the file directly
            }
        });
    
        try {
            const response = await apiClient({
                method: itemCreateApi.method,
                url: itemCreateApi.path,
                data: fd,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            dispatch(ItemActions.addItem(response.data));
            showSnackbar('Item created successfully!', { variant: 'success' });
            close(); // Close the modal
        } catch (error) {
            const errMsg = error?.response?.data ?? "An error occurred during creation.";
            showSnackbar(errMsg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <section className={`modal ${show ? 'scale-100' : 'scale-0'}`}>
            <section className="w-full md:w-[500px] min-h-[550px] bg-black rounded shadow-xl">
                <header className="centerRY justify-between p-3 border-b border-white-grey border-opacity-[.17]">
                    <h1 className="text-base text-white-grey font-[Montserrat-SB]">New Item</h1>
                    <Icon
                        icon={icons.times}
                        className="text-xl text-white-grey cursor-pointer"
                        onClick={close}
                    />
                </header>
                <main className="p-3 space-y-3">
                    <section className='p-2 border border-white border-opacity-[.08] rounded'>
                        <p className="text-xs mb-2 text-neutral-200">Total size: {formatSize(totalFileSize)} of 2MB</p>
                        <div className="grid grid-cols-4 gap-3">
                            <ImageContext.Provider value={{ totalFileSize, removeImage, setBase64Image, updateFileSizes }}>
                                {images.map((image, index) => (
                                    <ImageBox
                                        index={index}
                                        image={image}
                                    />
                                ))}
                            </ImageContext.Provider>
                        </div>
                        <small className='text-neutral-400 mt-1 centerRY gap-1'>
                            <Icon icon={icons.solidInfo} className='text-yellow text-xl' /> 
                            Image that comes first will be used as thumbnail
                        </small>
                    </section>
                    <div className="grid grid-cols-3 gap-2">
                        <div className="col-span-2">
                            <FormField
                                label="Title"
                                name="title"
                                value={form.title}
                                onChange={handleChange}
                            />
                        </div>
                        <FormField
                            label="Price"
                            name="price"
                            value={form.price}
                            onChange={handleChange}
                        />
                    </div>
                    <FormField
                        label="Sizes (separated with comma)"
                        name="sizes"
                        value={form.sizes}
                        onChange={handleChange}
                    />
                    <FormField
                        label="Description"
                        name="description"
                        value={form.description}
                        onChange={handleChange}
                        textarea={true}
                        optional={true}
                    />
                    <div className="centerRY justify-end font-[Montserrat-SB]">
                        <button
                            className="px-[12px] py-[6px] text-sm rounded bg-yellow text-black centerRXY gap-2"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            <span>{loading ? 'Creating...' : 'Create'}</span>
                            <Icon icon={loading ? icons.loading : icons.send} />
                        </button>
                    </div>
                </main>
            </section>
        </section>
    );
};



export default AdminProducts;
