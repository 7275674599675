import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiClient from '../../interceptor'
import endpoints from '../../constants/endpoints'

const initialState = {
  data: [],
  error: null,
  loading: false
}

const itemApi = endpoints.items;

export const ItemSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    setItems(state, {payload}) {
        state.data = payload
        state.initiated = false
    },
    addItem(state, {payload}) {
        state.data = [payload, ...state.data]
    },
    editItem(state, {payload}) {
        state.data = state.data.map(each => {
            if(each._id === payload._id) {
                each = {...each, ...payload}
            }
            return each;
        })
    },
    removeItem(state, {payload}) {
        state.data = state.data.filter(each => each._id !== payload);
    },
  },
    extraReducers: builder => {
        builder
            .addCase(fetchItems.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchItems.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(fetchItems.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
})


export const fetchItems = createAsyncThunk(
    'items/fetchItems',
    async(_, {rejectWithValue}) => {
        try {

            const response = await apiClient({
                method: itemApi.fetch.method,
                url: itemApi.fetch.path
            })
            return response.data;

        }catch(err) {
            console.log(err)
            if(err.response) {
                // server Error
                return rejectWithValue(err.response.data?.message || 'Internal Server Error');
            }
            else if(err.request) {
                // Nextwork error
                return rejectWithValue('Network error');
            }
            else {
                return rejectWithValue('An unexpected error occured')
            }
        }
    }
)

export const ItemActions = ItemSlice.actions
export default ItemSlice.reducer