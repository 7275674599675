const endpoints = {
    users: {
        login: {
            path: "/auth/signin",
            method: "post"
        },
        signup: {
            path: "/auth/signup",
            method: "post"
        },
        me: {
            path: "/auth/user",
            method: "get"
        }
    },
    items: {
        fetch: {
            path: "/products",
            method: "get"
        },
        find: {
            path: "/products",
            method: "get"
        },
        create: {
            path: "/products",
            method: "post"
        },
        update: {
            path: "/products",
            method: "put"
        },
        delete: {
            path: "/products",
            method: "delete"
        },
    },
    pay: {
        intitiate: { path: "/orders/pay", method: "post" },
        complete: { path: "/orders/complete", method: "post" }
    },
    orders: {
        fetchAll: {path: "/orders", method: "get"},
        myOrders: {path: "/orders/myOrders", method: "get"}
    }
}

export default endpoints