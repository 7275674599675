import React, { useState } from 'react'
import FormField from '../components/common/FormField'
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import useSnackbar from '../hooks/useSnackbar';
import apiClient from '../interceptor';
import endpoints from '../constants/endpoints';
import icons from '../constants/icons';

const signupApi = endpoints.users.signup

const Signup = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar(); 
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    password2: ''
  })

  // Formfield Change
  const handleChange = ({name, value}) => {
    setForm({ ...form, [name]: value})
  };

  // Clear Form 
  const clearForm = () => {
    setForm({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      password2: ''
    })
  }

  // Validate Form
  const validateForm = () => {

    const { firstName, lastName, email, password, password2 } = form;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if(!(firstName?.trim() && lastName?.trim() && email?.trim() && password?.trim() && password2?.trim())) {
      showSnackbar('All fields are required', { variant: 'error' });
      return false;
    }

    // Verify Fields
    if (password.trim() !== password2.trim()) {
      showSnackbar('Passwords do not match', { variant: 'error' });
      return false;
    }

    // Verify Email format
    if (!emailPattern.test(email)) {
      showSnackbar("Please enter a valid email address.", { variant: 'error' });
      return false;
    }
    return true;
  };

  

  const signupUser = async() => {
    const isValid = validateForm();
    if (!isValid) return;

    const { firstName, lastName, email, password } = form;

    setLoading(true);

    try {
      await apiClient({
        method: signupApi.method,
        url: signupApi.path,
        data: { firstName, lastName, email, password },
      });

      showSnackbar('Signup Successful', { variant: 'success' });
      clearForm()
      navigate('/login')

    } catch (err) {

      const error = err?.response?.data ?? "An error occurred";
      showSnackbar(error, { variant: 'error' });

    } finally {
      setLoading(false);
    }
  }


  return (
    <section className="container space-y-7">
        <Header />

        <section className="space-y-3 max-w-[625px] mx-auto">
            <div className="grid grid-cols-2 gap-3">
                <FormField 
                  label="First Name" 
                  name="firstName"
                  value={form.firstName}
                  onChange={handleChange}
                />
                <FormField 
                  label="Last Name" 
                  name="lastName"
                  value={form.lastName}
                  onChange={handleChange}
                />
            </div>
            <FormField 
              label="Email Address" 
              name="email"
              value={form.email}
              onChange={handleChange}
            />
            <div className="grid grid-cols-2 gap-3">
                <FormField 
                  label="Password" 
                  isPassword={true}
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                />
                <FormField 
                  label="Password Again" 
                  isPassword={true}
                  name="password2"
                  value={form.password2}
                  onChange={handleChange}
                />
            </div>
            <SubmitButton loading={loading} click={signupUser} />
        </section>
    </section>
  )
}

const SubmitButton = ({loading, click}) => {
    return (
        <section className="pt-5">
            <button className='w-full centerRXY gap-2 px-[12px] py-[8px] bg-yellow text-black font-[Montserrat-B] text-base rounded'
              disabled={loading}
              onClick={click}
            >
                Submit <Icon icon={loading? icons.loading:icons.send} className='text-xl text-black' />
            </button>
        </section>
    )
}

export const Header = () => {
    const navigate = useNavigate()
    return (
      <header className='flex flex-col items-center md:gap-y-1 font-[Montserrat-B]'>
        <h1 className='text-yellow text-[38px] md:text-[96px] text-center md:text-left'>
          Sign up
        </h1>
        <button className='text-white text-sm md:text-[18px]' onClick={() => navigate('/login')}>
          Login instead
        </button>
      </header>
    )
  }
  

export default Signup