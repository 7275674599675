import React, { useEffect, useState } from 'react'
import apiClient from '../../interceptor';
import endpoints from '../../constants/endpoints';
import { PageLoading } from '../../components/common/PageLoading';
import { useAuth } from '../../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';

const orderApi = endpoints.orders.myOrders;

export const MyOrder = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState();
    const { user, loading: authorizing } = useAuth()

    const fetchOrder = async() => {
        try {
            setLoading(true);

            const response = await apiClient({
                method: orderApi.method,
                url: orderApi.path
            })

            setOrders(response.data)

        }catch(err) {
            console.log(err)
        }finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(!authorizing && !user) {
            navigate('/collections')
        }else {
            fetchOrder()
        }
    }, [authorizing])

    if(authorizing) return <PageLoading />

    return (
        user.role !== 'regular' ? navigate('/collections') : <section className="container">
            <Heading />

            <section className="py-10">
                {
                    loading? <PageLoading /> :
                    <div>
                        {
                            orders?.length && orders.map(each => (
                                <section className='p-1 border-opacity-17 flex gap-2 rounded-md'>
                                    <img
                                        src={each.products[0].product.pictures[0]['file']}
                                        className='flex-none w-[100px] h-[100px] rounded-md'
                                    />
                                    <main className="grow p-1 space-y-1 flex flex-col gap-1">
                                            <p className='text-white-grey text-sm'>{each.products[0].product.title}</p>
                                            <p className='text-white-grey text-sm'>{each.paymentId}</p>
                                            
                                            <div className="centerRY gap-2">
                                                <p className='text-white text-sm shrink-0'>$ {each.totalPrice} &nbsp;for </p>
                                                <p className='text-white text-sm shrink-0'>{each.products[0].quantity} item</p>
                                                <div className="w-full md:w-max flex-none centerRY text-sm">
                                                    <button className="px-[12px] py-[6px] text-xs bg-black-coal rounded-md">
                                                    {each.orderStatus}
                                                    </button>
                                                </div>
                                            </div>
                                    </main>
                                </section>
                            ))
                        }
                    </div>
                }
            </section>
        </section>
    )
}

const Heading = () => {
    return (
      <div className='flex flex-col justify-center items-center gap-2 text-2xl md:text-4xl font-[Montserrat-B] md:py-5'>
        <h1 className="text-center">My <span className="text-yellow">Orders</span></h1>
        <div className="h-[4px] bg-white w-[50px]"></div>
      </div>
    );
};
