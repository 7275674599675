import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@iconify/react';
import gsap from 'gsap';
import { useAuth } from '../contexts/AuthProvider';
import icons from '../constants/icons';
import apiClient from '../interceptor';
import endpoints from '../constants/endpoints';

const intiateApi = endpoints.pay.intitiate

const ShowItem = ({ show, item, close }) => {
  const modalRef = useRef(null); // Ref for the modal container
  const [size, setSize] = useState(35);
  const [activeImage, setActiveImage] = useState(item.pictures[0])
  const [initiatingPay, setInitiatingPay] = useState(false)
  const {user} = useAuth()

  useEffect(() => {
    if (show) {
      gsap.to(modalRef.current, { 
        scale: 1, 
        opacity: 1, 
        duration: .5, 
        ease: 'power1.inOut',
      });
    } else {
      gsap.to(modalRef.current, { 
        scale: 0, 
        opacity: 0, 
        duration: .5, 
        ease: 'power1.inOut',
      });
    }
  }, [show]);

  const initiatePayment = async() => {
    try {
      setInitiatingPay(true)
        const response = await apiClient({
          method: intiateApi.method,
          url: intiateApi.path,
          data: {
            amount: item.price,
            items: JSON.stringify([{
              product: item._id,
              quantity: 1,
              price: item.price
            }])
          }
        });
        window.location.href = response.data
    }catch(err) {
        console.log(err)
    }
    setInitiatingPay(false)
  }

  return (
    <section ref={modalRef} className="modal fixed inset-0 scale-0 opacity-0">
      <div className="bg-black-coal w-[100vw] h-[100vh] p-5 overflow-y-scroll">
        <header className="bg-black-coal z-10 h-[60px] centerRY justify-between border-b border-white-grey border-opacity-[.17] sticky top-0">
          <Icon
            icon="lets-icons:arrow-left-light"
            className="text-3xl text-white-grey"
            role="button"
            onClick={close}
          />
          <Icon
            icon="clarity:times-line"
            className="border border-white-grey border-opacity-[.17] text-3xl text-white-grey"
            role="button"
            onClick={close}
          />
        </header>
        <main className="container grid grid-cols-1 md:grid-cols-2">
          <div className="p-1 md:p-3 space-y-3 pb-10">
            <span className="md:hidden">
              <ItemHead item={item} />
            </span>
            <img
              src={activeImage.file}
              className="rounded-xl md:h-[500px] w-auto mx-auto"
              alt=""
            />
            <div className="centerRXY gap-2">
              {
                item.pictures && item.pictures.map((image, index) => (
                  <img
                    src={image.file}
                    key={index}
                    className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] rounded-md"
                    alt="" role='button'
                    onClick={() => setActiveImage(image)}
                  />
                ))
              }
            </div>
          </div>
          <section className="md:border-l border-white-grey border-opacity-[.17] md:p-5">
            <span className="hidden md:block">
              <ItemHead item={item} />
            </span>
            <section className="md:min-h-[50px]">
              {
                item.description && 
                <div className="mt-3 md:mt-20 font-[Montserrat-R] text-base md:text-[25px] text-white-grey">
                  <p>Waybaq Cloud 9 Kaws offers maximum comfort with a fluffy interior design. It is much suitable for the bedroom.</p>
                </div>
              }
            </section>

            <div className="space-y-1 mt-3 md:mt-10">
              <p className="text-white font-[Montserrat-B] text-sm md:text-lg">Size</p>
              <div className="flex items-center flex-wrap gap-2">
                {item.sizes.map((each) => (
                  <button
                    key={each}
                    className={`w-[35px] h-[35px] md:w-[45px] md:h-[45px] text-white-grey font-[Montserrat-SB] text-sm md:text-base rounded shadow-xl transition-all centerRXY ${
                      each === size ? 'bg-black border border-white border-opacity-[.17]' : 'bg-[#333]'
                    }`}
                    onClick={() => setSize(each)}
                  >
                    {each}
                  </button>
                ))}
              </div>
            </div>

          {user && user.role === 'regular' && 
            <div className="centerRY justify-center md:justify-start gap-x-5 mt-5">
              <button className="px-[12px] md:px-[25px] py-[8px] md:py-[10px] bg-black text-white-grey centerRXY rounded-md text-sm md:text-base gap-2">
                Add to favorite <Icon icon="material-symbols:heart-plus-outline-rounded" className="text-lg" />
              </button>
              <button 
                className="px-[12px] md:px-[25px] py-[8px] md:py-[10px] bg-[#333] text-white-grey centerRXY rounded-md text-sm md:text-base gap-2"
                disabled={initiatingPay}
                onClick={initiatePayment}
              >
                {initiatingPay? 'Hold on' : 'Buy Now'} 
                <Icon icon={initiatingPay? icons.loading : "icons8:buy"} className="text-xl" />
              </button>
            </div>
          }
          </section>
        </main>
      </div>
    </section>
  );
};

const ItemHead = ({ item }) => {
  return (
    <div className="space-y-1 md:space-y-5 font-[Montserrat-B] text-[22px] md:text-[48px]">
      <h1 className="text-white md:leading-[40px]">{item.title}</h1>
      <p className="text-3xl text-yellow md:text-[48px]">${item.price}</p>
    </div>
  );
};

export default ShowItem;
