import React from 'react'
import { Icon } from '@iconify/react';
import icons from '../../constants/icons';

export const PageLoading = () => {
  return (
    <section className="min-h-[40vh] centerRXY">
        <Icon icon={icons.loading} className='text-2xl' />
    </section>
  )
}
