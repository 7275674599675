  import React, { useEffect, useRef } from 'react';
  import gsap from 'gsap';
  import ItemCard from '../components/common/ItemCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItems } from '../store/reducers/items';


  const Collections = () => {
    const collectionRef = useRef([]);
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.items);

    useEffect(() => {
      dispatch(fetchItems());
      
      gsap.fromTo(
        collectionRef.current,
        {
          opacity: 0,
          y: -80,
          x: -10,
          ease: 'power1.inOut',
        },
        {
          duration: .5, // slightly longer for smoother effect
          y: 0,
          x: 0,
          opacity: 1,
          delay: .2,
          stagger: .2, // delay between each animation
          ease: 'sine.out', // smooth easing for smoother animations
          orce3D: true
        }
      );
    }, [dispatch]);

    return (
      <section className='container'>
        <Heading />

        <div className="grid grid-cols-2 gap-2 md:grid-cols-3 xl:gap-5">
          {data.map((each, index) => (
            <ItemCard 
              item={each} 
              key={index} 
              ref={(el) => (collectionRef.current[index] = el)} 
            />
          ))}
        </div>
      </section>
    );
  };

  const Heading = () => {
    return (
      <div className='flex flex-col justify-center items-center gap-2 text-2xl md:text-4xl font-[Montserrat-B] md:py-5'>
        <h1 className="text-center">Top <span className="text-yellow">Collections</span></h1>
        <div className="h-[4px] bg-white w-[50px]"></div>
      </div>
    );
  };

  export default Collections;
