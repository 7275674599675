import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import useSnackbar from '../hooks/useSnackbar';
import endpoints from '../constants/endpoints';
import { Icon } from '@iconify/react';
import { ImageContext } from '../contexts/ImageContext';
import { ImageBox } from './items/ImageBox';
import FormField from './common/FormField';
import icons from '../constants/icons';
import apiClient from '../interceptor';
import { ItemActions } from '../store/reducers/items';

export const EditItem = ({ item, show, close }) => {
    const itemEditApi = endpoints.items.update;
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const [warnDelete, setWarnDelete] = useState(null)

    const [images, setImages] = useState([
        { image: "", file: undefined, size: 0 },
        { image: "", file: undefined, size: 0 },
        { image: "", file: undefined, size: 0 },
        { image: "", file: undefined, size: 0 }
    ]);

    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        title: '',
        description: '',
        price: 17.5,
        sizes: ''
    });

    useEffect(() => {
        if(show) {
          setForm({...item, sizes: item.sizes.join(", ")});
          setImages(currImages => { // fill pictures make sure array is 4 exactly
            return currImages.map((each, index) => (
                (index >= item.pictures.length)? each : {
                    image: item.pictures[index].file,
                    size: parseInt(item.pictures[index].size)
                }
            ))
          })
        }else {
            clearForm();
        }
    }, [show, item])

    const clearForm = () => {
        setImages([
            { image: "", size: 0 },
            { image: "", size: 0 },
            { image: "", size: 0 },
            { image: "", size: 0 }
        ])
        setForm({
            title: '',
            description: '',
            price: 17.5,
            sizes: ''
        })
    }

    // Convert bytes to KB or MB
    const formatSize = (bytes) => {
        if (bytes < 1024) return `${bytes} bytes`;
        else if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
        else return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
    };

    // Calculate total file size
    const totalFileSize = images.reduce((total, img) => total + img.size, 0);

    const handleChange = ({ name, value }) => {
        setForm({ ...form, [name]: value });
    };

    const removeImage = (index) => {
        if(images[index].image.startsWith('https://')) {
            // then it is not a new image
            setWarnDelete(images[index]);
            return;
        }
        setImages(currState => {
            const newState = [...currState];
            newState[index] = { image: "", size: 0 };
            return newState;
        });
    }

    const imageRemoved = () => {
        setImages(currState => {
            const newState = currState.filter(each => each.image !== warnDelete.image);
            return newState;
        });
        setWarnDelete(null);
    }

    const setBase64Image = (base64, file, index) => {
        setImages(currState => {
            const newState = [...currState];
            newState[index] = { image: base64, size: file.size, file };
            return newState;
        });
    };

    const updateFileSizes = (file, index) => {
        setImages(currState => {
            const newState = [...currState];
            newState[index].size = file.size;
            newState[index].file = file;
            return newState;
        });
    };

    const validateForm = () => {
        const { title, price, sizes } = form;

        if (!(title.trim() && price && sizes.trim())) {
            showSnackbar('Please fill in all required fields.', { variant: 'error' });
            return false;
        }

        const sizeArray = sizes.split(',')
                                .filter(each => each !== "" || each.trim() === "")
                                .map(each => each.trim())
        console.log(sizeArray);

        if(sizeArray.length < 1) {
            showSnackbar('Shoe sizes are required in this format 43,44,45', { variant: 'error' });
            return false;  
        }

        if (images.every(image => image.image === "")) {
            showSnackbar('Please upload at least one image.', { variant: 'error' });
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        setLoading(true);

        const sizeArray = form.sizes.split(',')
                                .filter(each => each !== "" || each.trim() === "")
                                .map(each => each.trim())
        try {
            const fd = new FormData();
            fd.append('_id', form._id)
            fd.append('title', form.title)
            fd.append('description', form.description)
            fd.append('price', form.price)
            fd.append('sizes', JSON.stringify(sizeArray));

            let pictures = []
            images.forEach(image => {
                if(image.file) { // A recent upload
                    fd.append('files', image.file);
                }else {
                    if((image.image && image.size)) {
                        pictures.push({
                            file: image.image,
                            size: image.size
                        })
                    }
                }
            })

            fd.append('pictures', JSON.stringify(pictures))

            const response = await apiClient({
                method: itemEditApi.method,
                url: itemEditApi.path,
                data: fd,
                headers: {
                    'Content-Type': 'multipart/form-data'  // Ensure proper header
                }
            });

            dispatch(ItemActions.editItem(response.data))

            showSnackbar('Item created successfully!', { variant: 'success' });
            close(); // Close the modal
        } catch (error) {
            const errMsg = error?.response?.data ?? "An error occurred during creation.";
            showSnackbar(errMsg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return <>
        <WarnB4Delete 
            image={warnDelete} 
            close={()=>setWarnDelete(null)} 
            imageRemoved={imageRemoved} 
            productId={item._id}
        />
        <section className={`modal ${show ? 'scale-100' : 'scale-0'}`}>
            <section className="w-full md:w-[500px] min-h-[550px] bg-black rounded shadow-xl">
                <header className="centerRY justify-between p-3 border-b border-white-grey border-opacity-[.17]">
                    <h1 className="text-base text-white-grey font-[Montserrat-SB]">New Item</h1>
                    <Icon
                        icon={icons.times}
                        className="text-xl text-white-grey cursor-pointer"
                        onClick={close}
                    />
                </header>
                <main className="p-3 space-y-3">
                    <section className='p-2 border border-white border-opacity-[.08] rounded'>
                        <p className="text-xs mb-2 text-neutral-200">Total size: {formatSize(totalFileSize)} of 2MB</p>
                        <div className="grid grid-cols-4 gap-3">
                            {images.map((image, index) => (
                                <ImageContext.Provider value={{ totalFileSize, removeImage, setBase64Image, updateFileSizes }} key={index}>
                                    <ImageBox
                                        index={index}
                                        image={image}
                                    />
                                </ImageContext.Provider>
                            ))}
                        </div>
                        <small className='text-neutral-400 mt-1 centerRY gap-1'>
                            <Icon icon={icons.solidInfo} className='text-yellow text-xl' /> 
                            Image that comes first will be used as thumbnail
                        </small>
                    </section>
                    <div className="grid grid-cols-3 gap-2">
                        <div className="col-span-2">
                            <FormField
                                label="Title"
                                name="title"
                                value={form.title}
                                onChange={handleChange}
                            />
                        </div>
                        <FormField
                            label="Price"
                            name="price"
                            value={form.price}
                            onChange={handleChange}
                        />
                    </div>
                    <FormField
                        label="Sizes (separated with comma)"
                        name="sizes"
                        value={form.sizes}
                        onChange={handleChange}
                    />
                    <FormField
                        label="Description"
                        name="description"
                        value={form.description}
                        onChange={handleChange}
                        textarea={true}
                        optional={true}
                    />
                    <div className="centerRY justify-end font-[Montserrat-SB]">
                        <button
                            className="px-[12px] py-[6px] text-sm rounded bg-yellow text-black centerRXY gap-2"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            <span>{loading ? 'Updating...' : 'Update'}</span>
                            <Icon icon={loading ? icons.loading : icons.send} />
                        </button>
                    </div>
                </main>
            </section>
        </section>
    </>
};

const WarnB4Delete = ({image, imageRemoved, close, productId}) => {
    const [deleting, setDeleting] = useState(false);
    const {showSnackbar} = useSnackbar()
    const dispatch = useDispatch()

    const removeImage = async() => {
        const baseApi = "/products/image"
        const method = "patch";

        try {
            setDeleting(true)

            const response = await apiClient({
                method: method,
                url: `${baseApi}/${productId}?url=${image.image}`,
            })

            dispatch(ItemActions.editItem(response.data))
            imageRemoved()
        } catch (error) {
            const errMsg = error?.response?.data ?? "An error occurred during creation.";
            showSnackbar(errMsg, { variant: 'error' });
        } finally {
            setDeleting(false);
        }
    }
    return (
        <section className={`modal zIndex2 ${image? 'scale-100':'scale-0'}`}>
            <section className="w-full md:w-[350px] min-h-[100px] bg-black rounded shadow-xl">
                <header className="centerRY justify-between p-3 border-b border-white-grey border-opacity-[.17]">
                    <h1 className="text-base text-white-grey font-[Montserrat-M]">Confirm Delete</h1>
                    <Icon
                        icon={icons.times}
                        className="text-xl text-white-grey cursor-pointer"
                        onClick={close}
                    />
                </header>
                <main className="p-3 space-y-3">
                    <p className="text-xs md:text-sm font-[Montserrat-L]">Are you sure you want this image? It is not a recent upload</p>
                    <img src={image?.image} alt="" className='w-[120px] h-[135px] rounded-md mx-auto' />
                    <div className="centerRY justify-end gap-3">
                        <button 
                        className="px-[12px] py-[6px] border border-white-grey border-opacity-[.2] text-xs text-white-grey rounded hover:bg-black-coal"
                        onClick={close}
                        >Cancel</button>
                        <button 
                        className="centerRY gap-1 px-[12px] py-[6px] border border-red-500 border-opacity-[.2] text-xs text-red-500 rounded hover:bg-black-coal"
                        onClick={removeImage}
                        >Delete {deleting && <Icon icon={icons.loading} className="text-white-grey" />}</button>
                    </div>
                </main>
            </section>
        </section>
    )
}