import React from 'react'
import { Icon } from '@iconify/react';
import icons from '../../constants/icons';
import { useNavigate } from 'react-router-dom';

export const OrderCancelled = () => {
    const navigate = useNavigate()

    return (
        <section className="container centerRXY min-h-[70vh] pt-20 ">
            <div className="bg-black-coal w-full h-[100vh] md:w-[500px] md:h-[350px] shadow-xl rounded-xl p-5">
                <h1 className='text-2xl font-[Montserrat-B] text-center py-2 text-white-grey'>
                    Order Cancelled !
                </h1>
                <div className="centerRXY my-10">
                    <Icon 
                        icon="clarity:error-line"
                        className='text-[64px] md:text-[150px] text-white-grey' 
                    />
                </div>
                <div className="py-2 font-[Montserrat-SB] centerRY gap-x-10">
                    <button 
                        className="centerRY gap-2 text-sm hover:underline transition"
                        onClick={() => navigate('/collections')}
                    >
                        <Icon icon="bi:arrow-left" className='text-yellow text-xl' />
                        Continue Shopping
                    </button>
                    <button className="centerRY gap-2 text-sm hover:underline transition">
                        <Icon icon={icons.buy} className='text-yellow text-xl' />
                        My Orders
                    </button>
                </div>
            </div>
        </section>
    )
}
