import React from 'react'

const About = () => {
  return (
    <section className="container space-y-7">
        <Heading />

        <div className="grid grid-cols-1 md:grid-cols-2 xl:gap-7">
            <section className='hidden md:grid grid-cols-4'>
                <div className='col-span-2 grid grid-cols-1 gap-y-5'>
                    <div>
                        <img 
                            src={require('../assets/images/items/6.jpeg')} 
                            className='w-[200px] h-[200px] shadow-xl md:w-[300px] md:h-[300px] rounded-xl'  
                            alt=""  
                        />
                    </div>
                    <div>
                        <img 
                            src={require('../assets/images/items/4.jpeg')} 
                            className='w-[200px] h-[200px] shadow-xl md:w-[300px] md:h-[300px] rounded-xl'    
                            alt=""
                        />
                    </div>
                </div>
                <div className='col-span-2 overflow-visible centerRY'>
                    <img 
                        src={require('../assets/images/items/2.jpeg')} 
                        className='w-[200px] h-[200px] shadow-xl md:w-[300px] md:h-[300px] rounded-xl -ml-[100px]'
                        alt=""
                    />
                </div>
            </section>
            <div className='space-y-7 font-[Montserrat-EL] md:text-[22px] md:leading-[42px]'>
                <p>At Waybaq, we believe that footwear is more than just an accessory—it's a reflection of individuality, style, and personality.</p>
                <p>
                Our passion for creativity drives us to offer one-of-a-kind, customized footwear that blends comfort with bold designs. Whether you're looking for sneakers, sandals, or formal shoes, every pair at Waybaq is crafted to reflect your unique taste and vision.</p>
                <p>From choosing your favorite colors and patterns to adding personal touches, we make sure every step in your shoes is as original as you are. Welcome to Waybaq—where fashion meets customization.</p>
            </div>
        </div>
    </section>
  )
}

const Heading = () => {
    return (
      <div className='flex flex-col justify-center items-center gap-2 text-2xl md:text-4xl font-[Montserrat-B] md:py-5'>
        <h1 className="text-center">
            About <span className="text-yellow"><span className='text-blue-dark'>Way</span>Baq</span>
        </h1>
        <div className="h-[4px] bg-white w-[50px]"></div>
      </div>
    )
}

export default About