import React, { createContext, useContext, useState, useEffect } from 'react';
import apiClient from '../interceptor';
import endpoints from '../constants/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';

// Create the context
const AuthContext = createContext(null);
const userApi = endpoints.users.me;

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation()
  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem('authToken')
    setUser(null);
    navigate('/login')
  }

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const token = localStorage.getItem('authToken'); // or wherever you store your token
        if (!token) {
          setLoading(false);
          return;
        }

        const response = await apiClient({
          method: userApi.method,
          url: userApi.path,
        })

        if (response.status === 200) {
          setUser(response.data.user); // Assuming user data is in response.data
        }
      } catch (error) {
        console.log(error)
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    verifyUser();
  }, [location]);

  return (
    <AuthContext.Provider value={{ user, setUser, loading, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
