import React, { useEffect, useState } from "react";
import ShowItem from "../ShowItem";
import { useAuth } from "../../contexts/AuthProvider";
import icons from "../../constants/icons";
import { Icon } from '@iconify/react';
import { useDispatch } from "react-redux";
import useSnackbar from "../../hooks/useSnackbar";
import { ItemActions } from "../../store/reducers/items";
import apiClient from "../../interceptor";
import endpoints from "../../constants/endpoints";
import { EditItem } from "../EditItem";
import { useLocation } from 'react-router-dom';

const ItemCard = React.forwardRef(({ item }, ref) => {
    const {showSnackbar} = useSnackbar();
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [showActionBtns, setShowActionBtns]  = useState(false)
    const {user} = useAuth();
    const location = useLocation()

    useEffect(() => {
        if(location.pathname.startsWith('/admin')) {
            setShowActionBtns(true)
        }
    }, [location])

    const showDetail = () => {
        // only regular user;
        if(!showActionBtns) { // We are not in admin page, show ofcourse
            setShow(true)
        }
        else if(user?.role==='regular' || !user) {
            setShow(true)
        }
    }

    const removeItem = async() => {
        try {
            setDeleting(true)
            const itemApi = endpoints.items.delete;
            const response = await apiClient({
                method: itemApi.method,
                url: `${itemApi.path}/${item._id}`
            })

            console.log(response);

            dispatch(ItemActions.removeItem(item._id))
            setConfirmDelete(false);
            showSnackbar("Successfully removed item")
        }catch(err) {
            console.log(err)
            showSnackbar(err.response?.data ?? 'An error occured', {variant: 'error'})
        }finally {
            setDeleting(false)
        }
    }
    
    return <>
        <div ref={ref} className='w-full h-[300px] md:h-[420px] xl:h-[475px] bg-black-coal p-1 border border-white-grey border-opacity-[.1] rounded-b-xl
        flex flex-col group hover:shadow-2xl transition relative group' role="button" onClick={showDetail}>
            <img 
                src={item.pictures[0].file} 
                className='flex-none h-[205px] md:h-[300px] xl:h-[350px] rounded-b-xl w-full object-cover'
                alt=""
            />
            <div className="grow p-3 md:p-5 font-[Montserrat-M] space-y-2">
                <h3 className='text-white-grey opacity-[.8] text-center text-xs md:text-base group-hover:opacity-[.9] ellipsis-2'>
                    {item.title}
                </h3>
                <p className="text-center text-yellow font-[Montserrat-B] text-base md:text-xl group-hover:text-amber-500">
                    $ {item.price}
                </p>
            </div>

            {
                user && user.role !== 'regular' && showActionBtns && <section className="absolute top-0 left-0 w-full h-full p-3 bg-[#00000059] flex justify-end gap-5 scale-0 group-hover:scale-100 transition">
                    <Icon 
                        icon={icons.eye}
                        className="text-white text-xl"
                        role="button"
                        onClick={() => setShow(true)}
                    />
                    <Icon 
                        icon={icons.edit}
                        className="text-white text-xl"
                        role="button"
                        onClick={() => setShowEdit(true)}

                    />
                    <Icon 
                        icon={icons.bin}
                        className="text-white text-xl"
                        role="button"
                        onClick={() => setConfirmDelete(true)}
                    />
                </section>
            }
        </div>

        <ShowItem show={show} item={item} close={() => setShow(false)} />

        {user && user.role !== 'regular' && confirmDelete && item && (
            <RemoveItem 
                item={item} 
                show={confirmDelete} 
                close={() => setConfirmDelete(false)} 
                remove={removeItem}
                deleting={deleting}
            />
        )} 
        {user && user.role !== 'regular' && showEdit && item &&(
            <EditItem
                item={item} 
                show={showEdit} 
                close={() => setShowEdit(false)} 
            />
        )} 
    </>
});


const RemoveItem = ({item, show, close, remove, deleting}) => {
    return (
        <section className={`modal ${show? 'scale-100':'scale-0'}`}>
            <section className="w-full md:w-[350px] min-h-[100px] bg-black rounded shadow-xl">
                <header className="centerRY justify-between p-3 border-b border-white-grey border-opacity-[.17]">
                    <h1 className="text-base text-white-grey font-[Montserrat-M]">Confirm Delete</h1>
                    <Icon
                        icon={icons.times}
                        className="text-xl text-white-grey cursor-pointer"
                        onClick={close}
                    />
                </header>
                <main className="p-3 space-y-3">
                    <p className="text-xs md:text-sm font-[Montserrat-L]">Are you sure you want to delete the item <strong>{item.title}</strong></p>
                    <div className="centerRY justify-end gap-3">
                        <button 
                        className="px-[12px] py-[6px] border border-white-grey border-opacity-[.2] text-xs text-white-grey rounded hover:bg-black-coal"
                        onClick={close}
                        >Cancel</button>
                        <button 
                        className="centerRY gap-1 px-[12px] py-[6px] border border-red-500 border-opacity-[.2] text-xs text-red-500 rounded hover:bg-black-coal"
                        onClick={remove}
                        >Delete {deleting && <Icon icon={icons.loading} className="text-white-grey" />}</button>
                    </div>
                </main>
            </section>
        </section>
    )
}

export default ItemCard