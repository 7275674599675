import React from 'react';
import { useCallback } from 'react';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

const useSnackbar = () => {
    const DismissAlert = ({ snackbarId }) => {
        return (
            <button 
                className='px-[12px] py-[8px] rounded text-white text-sm font-medium'
                onClick={() => closeSnackbar(snackbarId)}
            >
                Dismiss
            </button>
        );
    };

    const showSnackbar = useCallback((message, options) => {
        enqueueSnackbar(message, {
            ...options,
            action: (key) => <DismissAlert snackbarId={key} />
        });
    }, []);

    return { showSnackbar };
};

export default useSnackbar;
