import React, { useContext, useRef } from 'react'
import { ImageContext } from '../../contexts/ImageContext';
import useSnackbar from '../../hooks/useSnackbar';
import { Icon } from '@iconify/react';
import icons from '../../constants/icons';

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

export const ImageBox = ({ index, image }) => {
    const fileRef = useRef();
    const { showSnackbar } = useSnackbar();
    const { totalFileSize, removeImage, setBase64Image, updateFileSizes } = useContext(ImageContext)

    const trigger = () => {
        fileRef.current.click();
    };

    const prepareBase64 = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (file.size + totalFileSize > MAX_FILE_SIZE) {
                showSnackbar('Total file size exceeds 2MB. Please choose a smaller file.', { variant: 'error' });
                return;
            }

            if(!file.type.startsWith('image/')) {
                showSnackbar("Only image file is allowed", {variant: 'error'});
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result, file.size, index);
                updateFileSizes(file, index);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="bg-black border border-black-coal rounded p-1 space-y-1 group">
            <div className="h-[100px] centerRXY" onClick={trigger}>
                {
                    image.image ?
                    <img 
                        src={image.image} 
                        className='w-[100px] h-[100px] m-auto object-cover rounded' 
                        alt="preview" 
                    />
                    : <Icon icon={icons.image} className='text-white-grey opacity-80' />
                }
                <input type="file" ref={fileRef} className="hidden" onChange={prepareBase64} />
            </div>
            {
                image.image &&
                <button 
                    className="w-full py-[4px] text-xs group-hover:bg-black-coal rounded"
                    onClick={()=>removeImage(index)}
                >remove</button>
            }
        </div>
    );
}
