import React, { useEffect, useState } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import './layout.css'
import { Icon } from '@iconify/react';
import { SnackbarProvider } from 'notistack'
import { useAuth } from '../contexts/AuthProvider';
import icons from '../constants/icons';

export const DefaultLayout = () => {
  const [mobile, setMobile] = useState(false);
  const location = useLocation()

  useEffect(() => {
    setMobile(false)
  }, [location])

  return (
    <SnackbarProvider>
      <div className='min-h-[100vh]'>
          <DefaultHeader openNav={() => setMobile(true)} />
          <MobileNav show={mobile} close={() => setMobile(false)} />

          {/* <PageTransition> */}
            <Outlet />
          {/* </PageTransition> */}
      </div>
    </SnackbarProvider>
  )
}


const DefaultHeader = ({openNav}) => {
  const navigate = useNavigate()
  const {user, logout} = useAuth()

  return (
        <header className='h-[100px] flex items-center bg-black'>
            <div className="container flex items-center justify-between">
              <img src={require("../assets/images/logo.png")} alt="" className='h-[36px] w-auto' />

              <nav className='nav-links'>
                {
                  user && user.role === 'agba'? <></> : <>
                    {/* <NavLink to="/">Home</NavLink> */}
                    <NavLink to="/collections">Top Collections</NavLink>
                    <NavLink to="/about-us">About Us</NavLink>
                    <NavLink to="/contact-us">Get in touch</NavLink>
                  </>
                }
                {
                  user && user.role === 'regular'? <>
                    <NavLink to="/order/my-orders">My Orders</NavLink>
                  </> : user && user.role === 'agba' ?
                  <>
                    <NavLink to="/admin/products">All Products</NavLink>
                    <NavLink to="/admin/orders">Orders</NavLink>
                  </> : <></>
                }
              </nav>

              <nav className="flex items-center gap-3">
                {
                  user && user.role === 'agba'? <></> : (
                    <Icon icon="ph:heart-thin" className='text-2xl text-white-grey' />
                  )
                }
                {
                  !user?
                <button className='nav-login' onClick={() => navigate('/login')}>
                  Login <Icon icon="majesticons:login" className='text-xl text-white-grey' />
                </button> :
                <div className="flex items-center gap-2 md:border-opacity-17 rounded">
                  {
                    user && <button className='text-sm text-white-grey px-[8px] py-[6px] shrink-0'>
                      {user.firstName} {user?.lastName?.charAt(0)}
                    </button>
                  }
                  <button className='nav-login no-border hidden md:flex' onClick={()=>logout()}>
                    Logout <Icon icon={icons.logout} className='text-xl text-white-grey' />
                  </button>
                </div>
                }
                
                <Icon 
                  icon="iconamoon:menu-kebab-vertical-bold" 
                  className='nav-menu border-opacity-17' 
                  role='button'
                  onClick={openNav}  
                />
              </nav>
            </div>
        </header>
    )
}

const MobileNav = ({close, show}) => {
  const navigate = useNavigate();
  const { user, logout } = useAuth()


  return (
    <nav className={
      `w-full h-[100vh] bg-black fixed top-0 left-0 z-[1000] transition-all
      ${show? 'translate-x-0':'-translate-x-[100%]'}
    `}>
      <header className='centerRY justify-between p-3 py-5'>
        <img src={require("../assets/images/logo.png")} alt="" className='h-[36px] w-auto' />
        <Icon 
          icon={icons.times} 
          className='text-2xl border-opacity-17' 
          role='button' 
          onClick={close}
        />
      </header>

      <div className="flex flex-col items-center gap-2 py-10 gap-y-10">
        <nav className='flex flex-col items-center text-center text-white-grey gap-y-10'>
          {
            user && user.role === 'agba'? <></> : <>
              <NavLink to="/">Home</NavLink>
              <NavLink to="/collections">Top Collections</NavLink>
              <NavLink to="/about-us">About Us</NavLink>
              <NavLink to="/contact-us">Get in touch</NavLink>
            </>
          }
          {
              user && user.role === 'regular'? <>
                <NavLink to="/order/my-orders">My Orders</NavLink>
              </> : user && user.role === 'agba' ?
              <>
                <NavLink to="/admin/products">All Products</NavLink>
                <NavLink to="/admin/orders">Orders</NavLink>
              </> : <></>
            }
        </nav>

        <nav className="flex flex-col">
          {
            !user?
          <button className='nav-login' onClick={() => navigate('/login')}>
            Login <Icon icon="majesticons:login" className='text-xl text-white-grey' />
          </button> :
          <button className='nav-login logout' onClick={()=>logout()}>
            Logout <Icon icon={icons.logout} className='text-xl text-white-grey' />
          </button>
          }
        </nav>
      </div>
    </nav>
  )
}