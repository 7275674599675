import axios from 'axios';

// Create an Axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Base URL from environment variables
  headers: {
    'Content-Type': 'application/json', // Default headers
  },
});

// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // You can add tokens or modify the request config here
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => {
    // Process the response here if needed
    return response;
  },
  (error) => {
    // Handle response errors
    if (error.response?.status === 401) {
      // For example, handle unauthorized errors
      console.error('Unauthorized access - maybe redirect to login');
    }
    return Promise.reject(error);
  }
);

export default apiClient;
