import React from 'react'

export default function ShowEmpty() {
    return (
        <div className="centerRXY min-h-[100px]">
            <img
                alt="empty"
                src={require('../../assets/images/empty-content.png')}
                className='w-[300px] h-auto mt-10 opacity-20'
            />
        </div>
    );
};
