import React from 'react'

const FormField = ({
  label,
  placeholder,
  value,
  isPassword = false,
  name,
  onChange,
  textarea,
  required,
  optional
}) => {
    return (
      <section className='flex flex-col space-y-1'>
        <label className='font-[Roboto-L] text-sm text-white'>
          {label} 
          {required && !optional && <span className='text-red-500 text-lg'>*</span>}
          {!required && optional && <span className='italic'>&nbsp; (optional)</span>}
        </label>
        {
          !textarea? 
          <input 
            className='w-full h-[35px] md:h-[48px] bg-black-coal focus:outline focus:outline-yellow text-sm px-2 rounded' 
            type={isPassword ? "password" : "text"}
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={(e) => onChange({
              name: e.target.name, 
              value: e.target.value
            })}
          />
          :
          <textarea 
            className='w-full h-[100px] bg-black-coal focus:outline focus:outline-yellow text-sm px-2 rounded' 
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={(e) => onChange({
              name: e.target.name, 
              value: e.target.value
            })}
          />
        }
      </section>
    )
}

export default FormField