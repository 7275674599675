import React from 'react'
import { Icon } from '@iconify/react';
import FormField from '../components/common/FormField';

const Contact = () => {
  return <div className='container'>
    <Header />
    <section className="grid grid-cols-1 md:grid-cols-2 md:mt-5 gap-2">
      <section className='md:space-y-7'>
        <div className='py-7 md:py-10 space-y-5'>
          <ContactGroup 
            icon="mdi:address-marker" 
            title="Address"
            text="123 Maple Street Toronto, ON M4B 1B3 Canada"
          />
          <ContactGroup 
            icon="material-symbols-light:call" 
            title="Contact Number"
            text="+234-XXX-XX-000, +234-XXX-00-XXX"
          />
          <ContactGroup 
            icon="material-symbols-light:mail" 
            title="Email Address"
            text="contact@waybaq.com"
          />
        </div>

        <span className="hidden md:inline">
          <SendButton />
        </span>
        
      </section>

      <main className='space-y-3'>
        <div className="grid grid-cols-2 gap-3">
          <FormField label="First Name" />
          <FormField label="Last Name" />
        </div>
        <FormField label="Email Address" />
        <FormField label="Subject (optional)" />
        <FormField label="Please enter your message" textarea={true} />
        <div className="centerRY justify-end md:hidden">
          <SendButton />
        </div>
      </main>
    </section>
  </div>

}

export const Header = () => {
  return (
    <header className='flex flex-col md:gap-y-2 font-[Montserrat-B]'>
      <h1 className='text-yellow text-[38px] md:text-[45px] xl:text-[96px] xl:leading-[93px] text-center md:text-left'>
        Contact Us
      </h1>
      <p className='text-white text-base md:text-[32px] text-center md:text-left font-[Montserrat-EL] md:font-[Montserrat-B]'>
        Make an inquiry today!
      </p>
    </header>
  )
}

const SendButton = () => {
  return (
    <button className='centerRXY gap-2 px-[12px] py-[8px] bg-yellow text-black font-[Montserrat-B] text-sm rounded-xl'>
      Send Message <Icon icon="lets-icons:send-duotone" className='text-xl text-black' />
    </button>
  )
}

export const ContactGroup = ({icon, title, text}) => {
  return (
    <section className='flex flex-col gap-y-1'>
      <div className="centerRY gap-1 font-[Montserrat-M]">
        <Icon icon={icon} className='text-sm text-white' />
        <span className='text-xs text-white-grey opacity-[.8]'>{title}</span>
      </div>
      <div className='font-[Roboto-L] text-sm text-white opacity-[.76]'>{text}</div>
    </section>
  )
}

export default Contact