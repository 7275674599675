import React, { useState } from 'react'
import FormField from '../components/common/FormField'
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import useSnackbar from '../hooks/useSnackbar';
import endpoints from '../constants/endpoints';
import apiClient from '../interceptor';
import icons from '../constants/icons';

const loginApi = endpoints.users.login;

const Login = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar(); 

  // Form State
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    email: '',
    password: ''
  })

  // Formfield Change
  const handleChange = ({name, value}) => {
    setForm({ ...form, [name]: value})
  };

  // Clear Form
  const clearForm = () => setForm({email: '', password: ''})

  // Validate Form
  const validateForm = () => {

    const { email, password } = form;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Verify Fields
    if (!email.trim() || !password.trim()) {
      showSnackbar('All fields are required', { variant: 'error' });
      return false;
    }

    // Verify Email format
    if (!emailPattern.test(email)) {
      showSnackbar("Please enter a valid email address.", { variant: 'error' });
      return false;
    }
    return true;
  };

  const loginUser = async () => {

    const isValid = validateForm();
    if (!isValid) return;

    const { email, password } = form;

    setLoading(true);

    try {
      const response = await apiClient({
        method: loginApi.method,
        url: loginApi.path,
        data: { email, password },
      });

      localStorage.setItem('authToken', response.data)
      showSnackbar('Login Successful', { variant: 'success' });
      clearForm()
      navigate('/')

    } catch (err) {

      const error = err?.response?.data ?? "An error occurred";
      showSnackbar(error, { variant: 'error' });

    } finally {
      setLoading(false);
    }
  };


  return (
    <section className="container space-y-7">
        <Header />

        <section className="space-y-3 max-w-[500px] mx-auto">
            <FormField 
              label="Email Address"
              name="email"
              value={form.email}
              onChange={handleChange}
            />
            <FormField 
              label="Password"
              isPassword={true}
              name="password"
              value={form.password}
              onChange={handleChange}
            />
            <div className="flex items-start justify-between">
                <button className='font-[Montserrat-R] text-xs md:text-sm'>Can't remember password?</button>
                <SubmitButton loading={loading} click={() => loginUser()} />
            </div>
        </section>
    </section>
  )
}

const SubmitButton = ({loading, click}) => {
    return (
        <section className="pt-5">
            <button 
              className='centerRXY gap-2 px-[12px] py-[8px] bg-yellow text-black font-[Montserrat-B] text-base rounded'
              disabled={loading}
              onClick={click}
            >
                Submit <Icon icon={loading? icons.loading:icons.send} className='text-xl text-black' />
            </button>
        </section>
    )
}

export const Header = () => {
    const navigate = useNavigate()
    return (
      <header className='flex flex-col items-center md:gap-y-1 font-[Montserrat-B]'>
        <h1 className='text-yellow text-[38px] md:text-[96px] text-center md:text-left'>
          Login
        </h1>
        <button className='text-white text-sm md:text-[18px]' onClick={() => navigate('/signup')}>
          signup instead
        </button>
      </header>
    )
}
  

export default Login