import React from 'react'

export const Home = () => {
  return (
    <section className="container grid grid-cols-1 md:grid-cols-2">
      <main className='md:py-[80px]'>
        <h1 className='font-[Montserrat-Bl] text-white max-w-[96px] text-[45px] leading-[52px] md:text-[75px] md:leading-[80px] xl:text-[96px] xl:leading-[92px]'>Experience Maximum Comfort</h1>
        <p className='text-sm md:text-sm xl:text-[32px] font-[Montserrat-M] text-white opacity-[.74] mt-3 md:mt-10 mb-2 md:mb-7'>Customize to your desired design!</p>
        <div className="centerRY font-[Montserrat-M] gap-x-5">
          <button className='px-[15px] md:px-[18px] py-[8px] md:py-[10px] bg-gradient-to-r from-[#333787] to-[#5A5EB1] rounded-full md:rounded-md text-xs md:text-base'>Shop Now</button>
          <button className='px-[15px] md:px-[18px] py-[8px] md:py-[10px] bg-white text-[#333787] rounded-full md:rounded-md text-xs md:text-base'>Customize</button>
        </div>
      </main>
      <div className='centerRXY md:centerRY md:justify-end pt-[20px]'>
        <img src={require("../assets/images/1.png")} alt="" className='h-[52vh] w-auto md:w-[80%] md:h-auto' />
      </div>
    </section>
  )
}
