const icons = {
    home: "mdi:home",
    eye: "mdi:eye",
    eyeOff: "iconamoon:eye-off-fill",
    student: "mdi:account-student",
    notification: "iconamoon:notification-fill",
    logout: "hugeicons:logout-05",
    send: "lets-icons:send-duotone",
    settings: "mdi:account-cog",
    plus: "ph:plus-fill",
    edit: "ep:edit",
    cameraCog: "tabler:camera-cog",
    times: "clarity:times-line",
    bin: "wpf:full-trash",
    image: "majesticons:image",
    solidInfo: "clarity:info-solid",
    loading: "eos-icons:bubble-loading",
    buy: "icons8:buy",
    search: "uil:search"
}

export default icons;